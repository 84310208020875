import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Logo from '../../assets/images/sensing.png';

import './index.scss';

interface TransitionWaterfallProps {
  endTransitionHandler: (miliseconds: number) => void;
}

/**
 * Animated transition which will render the component in, and then will animate the component out after a brief delay. The parent
 * is then informed when the animation has completed, and it passes number of miliseconds to wait before the parent can be unmounted
 * @param {function} endTransitionHandler - Parent function used to inform the parent that the animation has ended. The amount of
 * miliseconds that are required for the exit transition to complete must be passed to the parent to ensure the parent is not
 * unmounted before the animation completes
 */
const TransitionWaterfall = (props: TransitionWaterfallProps) => {
  const contentRoot = document.getElementById('sensing-main-content');
  const [waterRunning, setWaterRunning] = useState(true);

  const endWaterfall = async () => {
    setTimeout(() => {
      setWaterRunning(false);
      props.endTransitionHandler(1050);
    }, 2050);
  };

  if (!contentRoot) {
    return null;
  }

  return createPortal(
    <div className="rtsensing-nav-transition-wave-container">
      <CSSTransition
        in={waterRunning}
        timeout={1050}
        classNames="logo-transition"
        unmountOnExit
        appear
      >
        <img src={Logo} className="waterfall-logo" alt={'amgen-logo'} />
      </CSSTransition>
      <CSSTransition
        in={waterRunning}
        timeout={300}
        classNames="background-transition"
        unmountOnExit
        appear
      >
        <div className="background"></div>
      </CSSTransition>
      <CSSTransition
        in={waterRunning}
        timeout={1050}
        classNames="list-transition"
        onEntered={endWaterfall}
        unmountOnExit
        appear
      >
        <div className="list-body" style={{ transitionDelay: `500ms` }} />
      </CSSTransition>
      <CSSTransition
        in={waterRunning}
        timeout={950}
        classNames="list-transition"
        unmountOnExit
        appear
      >
        <div className="list-body" style={{ transitionDelay: `400ms` }} />
      </CSSTransition>
      <CSSTransition
        in={waterRunning}
        timeout={850}
        classNames="list-transition"
        unmountOnExit
        appear
      >
        <div className="list-body" style={{ transitionDelay: `300ms` }} />
      </CSSTransition>
      <CSSTransition
        in={waterRunning}
        timeout={750}
        classNames="list-transition"
        unmountOnExit
        appear
      >
        <div className="list-body" style={{ transitionDelay: `200ms` }} />
      </CSSTransition>
      <CSSTransition
        in={waterRunning}
        timeout={650}
        classNames="list-transition"
        unmountOnExit
        appear
      >
        <div className="list-body" style={{ transitionDelay: `100ms` }} />
      </CSSTransition>
    </div>,
    contentRoot,
  );
};

export default TransitionWaterfall;
