import React from 'react';
import { NavSection } from 'types';
import './index.scss';

interface SectionTitleProps {
  color?: string;
  section: NavSection;
  pageName?: string;
}

const SectionTitle = (props: SectionTitleProps) => {
  const { color, section, pageName } = props;
  return (
    <p className={ pageName?.toUpperCase() === "INTELLIGENCE" ? "nav-sub-section-title intelligence-section-title" : "nav-sub-section-title"} style={{ color: color }}>
      {section.sectionLabel.toUpperCase()}
    </p>
  );
};

export default SectionTitle;
