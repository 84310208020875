import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@gitlab-rtsensing/component-library';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './index.scss';

interface SectionLinkProps {
  className?: string;
  children: React.ReactNode;
  url: string;
  disabled?: boolean;
  type: 'internal' | 'external' | 'internalSection';
  submenu?: { label: string; url?: string | undefined; id?: string | undefined; link?: string | undefined }[];
  pageName?: string;
}

const SectionLink = (props: SectionLinkProps) => {
  const { className, disabled = false, children, url, type, submenu, pageName } = props;
  const navigate = useNavigate();
  const joinedClass =  `nav-sub-section-link nav-sub-section-link${disabled ? '--disabled' : '--enabled'
  } nav-sub-section-link${window.location.pathname  === "/intelligence" && url === "/intelligence" ?  '--active' :  window.location.pathname.includes(url) && url !== "/intelligence" ? '--active' : '--notactive'
  } ${className ? className : ''}`;


  return (
    <>
      {type === 'external' && (
        <div className={pageName?.toUpperCase() === "INTELLIGENCE" ? "intelligence-link-block" : ""} >
          <a className={pageName?.toUpperCase() === "INTELLIGENCE" ? joinedClass + "intelligence-link" : joinedClass}  href={url} target="_blank" rel="noreferrer">
            {children}
            <ExternalLinkIcon
              className={pageName?.toUpperCase() === "INTELLIGENCE" ? "intelligence-link-icon-external" : "intelligence-link-icon"}
              width={16}
              height={16}
            />
          </a>
        </div>
      )}
      {type === 'internal' && (
        <div>
          <div className={pageName?.toUpperCase() === "INTELLIGENCE" ? `intelligence-link-block  nav-sub-section-link${window.location.pathname === "/intelligence" && url === "/intelligence" ? '--active' : window.location.pathname.includes(url) && url !== "/intelligence" ? '--active' : '--notactive'
            }` : ""}>
            <Link className={pageName?.toUpperCase() === "INTELLIGENCE" ? joinedClass + "intelligence-link" : joinedClass} to={url}>
              {children}
            </Link>
          </div>
          {submenu && submenu.length > 0 && submenu[0]?.link === window.location.pathname && (
            <div className="nav-sub-section-link__submenu intelligence-link">
              {submenu.map((subitem: any, index: number) => (
                <AnchorLink
                  key={index}
                  onClick={() => {
                    navigate(subitem.url);
                  }}
                  href={subitem.url}
                  className={`nav-sub-section-link intelligence-link  nav-sub-section-link${window.location.hash === subitem.url ? '--active' : '--notactive'}`}
                >
                  {subitem.label}
                </AnchorLink>
              ))}
            </div>
          )}
        </div>
      )}

      {type === 'internalSection' && (
        <AnchorLink
          offset={() => 70}
          onClick={() => {
            navigate(url);
          }}
          className={joinedClass}
          href={url}
          rel="noreferrer"
        >
          {children}
        </AnchorLink>
      )}
    </>
  );
};

export default SectionLink;
