import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Link, useLocation, Location } from 'react-router-dom';
import Logo from '../assets/images/amgen_logo_white.png';
import NeuralWebSvg from 'components/svgs/neural-web-svg';
import DesktopSideNav from 'desktop-side-nav';
import { links as initialLinks } from 'mocks/links';
import { NavLink, NavSection, Permission, megaMenuKeys } from 'types';
import MobileSideNav from 'mobile-side-nav';
import FeedbackModal from 'components/feedback-modal';
import TransitionWaterfall from 'components/transition-waterfall';
import Footer from 'footer';
import './index.scss';
import useNavManager from 'nav-manager/useNavManager';
import { caseInsensitiveMatch, getEnv } from 'utils/';
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';
import MegaMenu from 'mega-menu';
import MegaMenuContents from 'mega-menu-contents';
import { MegaMenuManager, IMegaMenuSection } from 'mega-menu-manager';

export type Contents = { [key: string]: IMegaMenuSection[] };

const getRootRoute = (location: Location) => {
  return `/${location.pathname.split('/')[1]}`;
};
interface MatamoWindow extends Window {
  _paq?: Array<Array<string | number>>;
}

interface MainNavProps {
  themeService?: unknown;
  permissions: Permission;
}

const ENV = getEnv();

export const MainNav = (props: MainNavProps) => {
  const { permissions } = props;

  const megaMenuManager = useMemo(
    () => new MegaMenuManager(permissions),
    [permissions],
  );

  const location = useLocation();
  const { links, metaData } = useNavManager(initialLinks);
  const [hideMainNavBar, setHideMainNavBar] = useState(false);
  const [hideSideNavBar, setHideSideNavBar] = useState(false);
  const [authorizedLinks, setAuthorizedLinks] = useState<NavLink[]>([]);
  const [startTransition, setStartTransition] = useState(false);
  const [feedbackOpened, setFeedbackOpened] = useState(false);
  const [feedbackSource, setFeedbackSource] = useState('');
  const [currentRootRoute, setCurrentRootRoute] = useState(() =>
    getRootRoute(location),
  );
  const trackPageView = useCallback((location: Location) => {
    const matomoWindow: MatamoWindow = window;
    let pageName = location.pathname.substring(1);
    const scripts = document.getElementsByTagName('script');

    /**
     * Before tracking a new pageView, remove the current
     * heatmapSessionRecording script to prevent future duplication
     * of the script when a new pageView is tracked
     *  */
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].src.includes('HeatmapSessionRecording')) {
        scripts[i].remove();
      }
    }

    if (matomoWindow._paq) {
      pageName = pageName ? pageName.toUpperCase() : 'HOME';
      matomoWindow._paq.push(['setCustomUrl', location.pathname]);
      matomoWindow._paq.push(['setDocumentTitle', pageName]);
      matomoWindow._paq.push(['trackPageView']);
    }
  }, []);

  const invokeMatomoHeartbeat = useCallback(() => {
    const matomoWindow: MatamoWindow = window;

    if (matomoWindow._paq) {
      matomoWindow._paq.push(['enableHeartBeatTimer', 15]);
    }
  }, []);

  /**
   * Initial Matomo Page View
   * Upon loading the Nav and setting the initial route, we must enable the heartbeat
   * timer to enforce accurate capturing of a users time on page
   * https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page
   */
  useEffect(() => {
    invokeMatomoHeartbeat();
  }, [invokeMatomoHeartbeat]);

  useEffect(() => {
    const authLinks = links.map((link: NavLink) => {
      const authLink = { ...link };
      const linkKey = link.id ?? '';
      const linkIsAuth = permissions[linkKey];
      authLink.authorized = linkIsAuth;

      if (linkKey === 'admin') {
        adminRealTimeUsage(authLink);
      }

      if (
        linkKey === 'home' &&
        authLink.internalSections &&
        authLink.internalSections[0].links
      ) {
        authLink.internalSections = [...authLink.internalSections];
        authLink.internalSections[0] = { ...authLink.internalSections[0] };
        authLink.internalSections[0].links = [
          ...authLink.internalSections[0].links,
        ];
        authLink.internalSections[0].links =
          authLink.internalSections[0].links.map((intLink: NavLink) => {
            const authSubLink = { ...intLink };
            const intLinkKey = intLink.id ?? '';
            const linkIsAuth = !!permissions[intLinkKey];
            authSubLink.authorized = linkIsAuth;
            return authSubLink;
          });
      }

      return authLink;
    });
    setAuthorizedLinks(authLinks);
  }, [links, permissions]);

  useEffect(() => {
    const routeChange = getRootRoute(location);
    const routeChanged = routeChange !== currentRootRoute;

    if (routeChanged) {
      setStartTransition(true);
    }

    if (routeChanged) {
      trackPageView(location);
    }

    setCurrentRootRoute(getRootRoute(location));
  }, [location, currentRootRoute, trackPageView]);

  const matchLink = (checkedURL: string | undefined) => {
    let isMatched = false;
    if (checkedURL === '/home') {
      isMatched =
        caseInsensitiveMatch(checkedURL, currentRootRoute) ||
        '/' === currentRootRoute;
    } else if (checkedURL) {
      isMatched = caseInsensitiveMatch(checkedURL, currentRootRoute);
    }

    return isMatched;
  };

  const [megaMenuContents, setMegaMenuContents] = useState<
    undefined | Contents
  >(undefined);
  const [dropdownIds, setDropdownIds] = useState<[] | string[]>([]);
  const [currentMegaMenu, setCurrentMegaMenu] = useState<undefined | string>(
    undefined,
  );

  useEffect(() => {
    const menuSections = megaMenuManager.generateMenuSections();
    menuSections
      .then(sections => {
        setMegaMenuContents(sections);
        setDropdownIds(Object.keys(sections));
      })
      .catch(error => {
        console.log(error);
        setMegaMenuContents(undefined);
        setDropdownIds([]);
      });
  }, [megaMenuManager]);

  const handleMenuOpen = useCallback(
    (id: string) => {
      if (megaMenuKeys.includes(id)) {
        if (id !== currentMegaMenu) {
          setCurrentMegaMenu(id);
          return;
        }
        return;
      } else setCurrentMegaMenu(undefined);
    },
    [currentMegaMenu],
  );

  const ref = useRef(null);
  const handleCloseMegaMenu = () => setCurrentMegaMenu(undefined);
  // useOnClickOutside(ref, handleCloseMegaMenu);

  const buildLinks = () => {
    if (dropdownIds && megaMenuContents) {
      return authorizedLinks.map((linkData, index) => {
        const isActive = matchLink(linkData.url);
        const isAuth = linkData.authorized;

        const activeClass = isActive
          ? 'main-nav-link-text--active'
          : 'main-nav-link-text--inactive';

        const to = linkData?.url ?? '/';

        return (
          <React.Fragment key={index}>
            {isAuth && (
              <Link
                className={`main-nav-link`}
                to={to}
                onMouseOver={() => handleMenuOpen(linkData?.id as string)}
              >
                <p
                  title={linkData.label}
                  className={`ops-font-inter main-nav-link-text main-nav-link-text--standard ${activeClass}`}
                >
                  {linkData.label}
                </p>
              </Link>
            )}
          </React.Fragment>
        );
      });
    }
  };

  const getRouteData = () => {
    const foundData = authorizedLinks.find(item => matchLink(item.url));
    if (foundData) {
      if (foundData.authorized) {
        return foundData;
      } else {
        return { label: 'Not Authorized' } as NavLink;
      }
    } else {
      return { label: 'Not Found' } as NavLink;
    }
  };

  const openFeedbackModal = (type: 'CONTACT' | 'FEEDBACK' | 'ISSUE') => {
    const sources = {
      CONTACT:
        'https://app.smartsheet.com/b/form/71afbe95cd2f4b60af61e7535d9c6da3',
      FEEDBACK:
        'https://app.smartsheet.com/b/form/3575576ab3f4480bb6d377f8465b2b2b',
      ISSUE:
        'https://app.smartsheet.com/b/form/2507ea35bef342cda8325c4f01023cea',
    };

    const activatedSource = sources[type];

    if (activatedSource) {
      setFeedbackOpened(true);
      setFeedbackSource(activatedSource);
    }
  };

  const feedbackModalClosed = () => {
    setFeedbackOpened(false);
    setFeedbackSource('');
  };

  const endTransitionHandler = (totalTimeout: number) => {
    const timeout = totalTimeout;
    setTimeout(() => {
      setStartTransition(false);
    }, timeout);
  };

  /**
   *
   * @param authLink : Accept Navigation Link as parameter
   * @description : Use to Modify default Matomo  and grafana link according to different environment
   */
  const adminRealTimeUsage = (authLink: NavLink) => {
    //Bypass prod case, no computation required
    if (ENV !== 'prod') {
      authLink.externalSections?.map((linkObj: NavSection) => {
        const prodSiteId = '37'; //matamo siteId prod
        const urlLinkArr = linkObj.links[0].url
          ? linkObj.links[0].url.split(prodSiteId)
          : [];
        const newLink = urlLinkArr.join(ENV);
        // eslint-disable-next-line no-param-reassign
        linkObj.links[0].url =
          urlLinkArr.length === 0 ? linkObj.links[0].url : newLink;
        return newLink;
      });
      //Below change for Admin Graphana Link : Start
      if (ENV !== 'prod' && ENV !== 'stg') {
        authLink.externalSections?.map((linkObj: NavSection) => {
          const newLink =
            'https://grafana.nimbus.amgen.com/d/aa87b1ab-8f3a-4f55-8d5d-db6081b4c462/cronjobs-status-for-dev-and-test-env?orgId=1';

          // eslint-disable-next-line no-param-reassign
          linkObj.links[2].url = newLink;
          return newLink;
        });
      }
    }
  };

  function scrollIntoViewWithOffset(element: HTMLElement, offset: number) {
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      });
    }
  }

  useEffect(() => {
    // if not a hash link, scroll to top
    if (location.hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          scrollIntoViewWithOffset(element, 60);
        }
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    if (metaData && metaData.hideMainNavBar === true) {
      setHideMainNavBar(true);
    } else {
      setHideMainNavBar(false);
    }

    if (metaData && metaData.hideSideNavBar === true) {
      setHideSideNavBar(true);
    } else {
      setHideSideNavBar(false);
    }
  }, [metaData]);

  return (
    <ThemeProvider themeService={props.themeService}>
      <React.Fragment>
        <nav className="main-nav">
          <NeuralWebSvg className="main-nav-neural-web" />
          <div className="main-nav-logo-block">
            <img src={Logo} alt="amgen logo" />
            <Link className="main-nav-link" to="/">
              <p className="main-nav-link-text main-nav-link-text--large ops-font-roboto">
                Sensing
              </p>
            </Link>
          </div>
          <div
            className={`main-nav-link-block ${
              hideMainNavBar ? 'sensing-nav-hide' : ''
            }`}
          >
            {buildLinks()}
          </div>
          <MegaMenu
            isActive={!!currentMegaMenu}
            forwardRef={ref}
            onMouseLeave={() => handleCloseMegaMenu()}
          >
            <MegaMenuContents
              {...{
                currentMegaMenu,
                data: megaMenuContents,
                handleClose: handleCloseMegaMenu,
              }}
            />
          </MegaMenu>
        </nav>
        <DesktopSideNav
          hideNav={hideSideNavBar}
          activeRouteData={getRouteData()}
        />
        <MobileSideNav
          routeData={authorizedLinks}
          activeRouteData={getRouteData()}
          hideNav={hideMainNavBar}
        />
        <Footer
          activeRouteData={getRouteData()}
          toggleFeedbackModal={openFeedbackModal}
        />
        {startTransition && (
          <TransitionWaterfall endTransitionHandler={endTransitionHandler} />
        )}
        <FeedbackModal
          feedbackSource={feedbackSource}
          opened={feedbackOpened}
          onFeedbackModalClosed={feedbackModalClosed}
        />
      </React.Fragment>
    </ThemeProvider>
  );
};
