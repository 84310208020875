import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { NavLink, NavSection } from 'types';
import { ChevronIcon } from 'icons';
import { FadeTransition } from 'components/fade-transition';
import SectionLink from './section-link';
import SectionTitle from './section-title';
import './index.scss';
import { CaretUpIcon, CaretDownIcon, Button } from '@opsdti-global-component-library/amgen-design-system'

interface DesktopSideNavProps {
  activeRouteData: NavLink;
  hideNav: boolean;
}

export const DesktopSideNav = (props: DesktopSideNavProps) => {
  const { activeRouteData, hideNav } = props;
  const [opened, setOpened] = useState(() => window.innerWidth >= 1400);
  const [showInternalLink, setInternalLink] = useState(true);
  const [showExternalLink, setExternalLink] = useState(true);
  const navRoot = document.getElementById('sensing-desktop-side-nav');
  const contentRoot = document.getElementById('sensing-main-content');

  useEffect(() => {
    if (navRoot && contentRoot) {
      if (opened) {
        navRoot.classList.add('nav-active');
        contentRoot.classList.add('nav-active');
      } else {
        navRoot.classList.remove('nav-active');
        contentRoot.classList.remove('nav-active');
      }
    }
  }, [opened, navRoot, contentRoot]);

  useEffect(() => {
    setInternalLink(true);
    setExternalLink(true);
  }, [activeRouteData])

  if (hideNav) {
    if (navRoot && contentRoot) {
      navRoot.classList.add('sensing-nav-hide');
      navRoot.classList.remove('nav-active');
      contentRoot.classList.remove('nav-active');
    }
  } else {
    if (navRoot) {
      navRoot.classList.remove('sensing-nav-hide');
    }
  }

  const toggleNav = () => {
    setOpened(!opened);
  };

  const renderSubLinks = (
    sectionData: Array<NavSection>,
    linkType: 'internal' | 'external' | 'internalSection',
  ) => {
    const subLinkSections: Array<JSX.Element> = [];
    if (sectionData.length) {
      sectionData.forEach(section => {
        subLinkSections.push(
          <SectionTitle key={section.sectionLabel} section={section} pageName={activeRouteData.label} />,
        );

        if (section.links && section.links.length) {
          section.links.forEach((link, index) => {
            if (link.authorized !== false) {
              subLinkSections.push(
                <SectionLink
                  key={link.label + index}
                  type={linkType}
                  url={link.url ?? '#'}
                  disabled={link.disabled}
                  submenu={link.subMenu}
                  pageName={activeRouteData.label}
                >
                  {link.label}
                </SectionLink>,
              );
            }
          });
        }
      });
    }

    return subLinkSections;
  };

  if (!navRoot) {
    return null;
  }

  return createPortal(
    <div>
      {activeRouteData.url && (
        <>
          <div className="nav-header-block">
            <FadeTransition itemKey={activeRouteData.label}>
              <p className="nav-header-title">
                {activeRouteData.label.toUpperCase() === 'INTELLIGENCE' ? activeRouteData.label : activeRouteData.label.toUpperCase()}
              </p>
            </FadeTransition>
            <button
              className={`sensing-nav-side-toggle-button  ${activeRouteData.label.toUpperCase() === 'INTELLIGENCE' ? 'intelligence-nav-btn' : ''} ${opened ? 'sensing-nav-side-toggle-button--nav-active' : ''
                }`}
              onClick={toggleNav}
            >
              <ChevronIcon direction={`${opened ? 'LEFT' : 'RIGHT'}`} />
            </button>
          </div>
          <FadeTransition itemKey={activeRouteData.label}>
            <div className='link-container'>
              {activeRouteData.jumpToSections?.length ? (
                <div className="nav-sub-links-block">
                  {renderSubLinks(
                    activeRouteData.jumpToSections,
                    'internalSection',
                  )}
                </div>
              ) : (
                <div />
              )}
              {activeRouteData.internalSections?.length ? (
                <div className={activeRouteData.label.toUpperCase() === "INTELLIGENCE" ? "intelligence-links-block" : "nav-sub-links-block"}>
                  {activeRouteData.label.toUpperCase() === 'INTELLIGENCE' &&
                    <div className="quick-link-block">
                      <p className="quick-link-title">
                        Quick Links
                      </p>
                      <Button
                        className=''
                        type='link'
                        icon={showInternalLink ? <CaretUpIcon height={24} width={24} color='black' /> : <CaretDownIcon height={24} width={24} color='black' />}
                        onClick={() => setInternalLink(!showInternalLink)}
                      />
                    </div>
                  }
                  {showInternalLink && renderSubLinks(activeRouteData.internalSections, 'internal')}
                </div>
              ) : (
                <div />
              )}
              {activeRouteData.externalSections?.length ? (
                <div className={activeRouteData.label.toUpperCase() === "INTELLIGENCE" ? "intelligence-links-block" : "nav-sub-links-block"}>
                  {activeRouteData.label.toUpperCase() === 'INTELLIGENCE' &&
                    <div className="quick-link-block">
                      <p className="quick-link-title">
                        Related Links
                      </p>
                      <Button
                        className=''
                        type='link'
                        icon={showExternalLink ? <CaretUpIcon height={24} width={24} color='black' /> : <CaretDownIcon height={24} width={24} color='black' />}
                        onClick={() => setExternalLink(!showExternalLink)}
                      />
                    </div>
                  }
                  {showExternalLink && renderSubLinks(activeRouteData.externalSections, 'external')}
                </div>
              ) : (
                <div />
              )}
            </div>
          </FadeTransition>
        </>
      )
      }
    </div >,
    navRoot,
  );
};

export default DesktopSideNav;
